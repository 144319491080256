<template>
  <div class="personal-center">
    <div class="title">
      <h1>个人中心</h1>
      <p>Personal center</p>
    </div>
    <div class="body">
      <img class="avatar" src="@/assets/imgs/user-avatar.png"/>
      <p class="name">{{userInfo.name}}</p>
      <!-- <p class="account">账号：<span class="value">{{userInfo.exp}}</span></p> -->
      <el-button @click="editPwd">修改密码</el-button>
    </div>

    <!-- 修改密码弹框 -->
    <el-dialog v-model="editDialogVisible" width="600px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>修改密码</h1>
        <p>change Password</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="edit-form" :rules="editFormRules">
        <el-form-item label="原密码" prop="old">
          <el-input v-model="editFormData.old" show-password placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newest">
          <el-input v-model="editFormData.newest" show-password placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="sure">
          <el-input v-model="editFormData.sure" show-password placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {parseJwt} from '@/utils/common'
import { sys } from '@/api'
import { encode } from 'js-base64'

export default {
  name: 'PersonalCenter',
  data() {
    return {
      editDialogVisible: false, // 修改弹框visible
      editFormData: { // 修改密码表单
        old: '',
        newest: '',
        sure: '',
      },
      editFormRules: { // 修改表单的校验
        old: { required: true, message: '请输入', trigger: 'blur' },
        newest: { required: true, message: '请输入', trigger: 'blur' },
        sure: { required: true, message: '请输入', trigger: 'blur' },
      },
    }
  },
  computed: {
    userInfo() {
      if(localStorage.getItem('wlzcToken')) {
        return parseJwt(localStorage.getItem('wlzcToken').split(' ')[1])
      }
      return ''
    }
  },
  methods: {
    // 点击修改密码
    editPwd() {
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {}
          for(let key in this.editFormData) {
            params[key] = encode(this.editFormData[key])
          }

          const {code, msg} = await sys.updatePwd(params)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`修改成功`)
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.personal-center {
  height: 100%;
  padding: 30px;
  background: linear-gradient(123deg, #2E2E44 0%, #1B1B27 100%);
  box-shadow: 0px 11px 14px 0px rgba(9, 9, 13, 0.65);
  border-radius: 16px;
  .title {
    h1 {
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 14px;
      color: rgba($color: #ffffff, $alpha: .6);
    }
  }
  .body {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    height: calc(100% - 72px);
    margin-top: 26px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .avatar {
      width: 88px;
    }
    .name {
      font-size: 16px;
      margin-top: 16px;
    }
    .account {
      font-size: 12px;
      margin-top: 16px;
      .value {
        font-size: 14px;
      }
    }
    .el-button {
      width: 170px;
      height: 36px;
      background: linear-gradient(132deg, #3D3D59 0%, #3B3B56 100%);
      border-radius: 8px;
      margin-top: 50px;
    }
  }
  .edit-form {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    padding: 30px 30px 10px;
    .el-form-item {
      ::v-deep .el-form-item__label{
        width: 80px;
        color: #B4B4C5;
      }
    }
  }
}
</style>